import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchProducts, updateProduct } from '../api';

const ProductTable = ({ onLogout }) => {
  const [products, setProducts] = useState([]);
  const [searchSku, setSearchSku] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchSku) {
      handleSearch();
    }
  }, [searchSku]);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchProducts(searchSku);
      setProducts(prevProducts => [...prevProducts, ...data]);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Error al obtener productos. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStock = async (productId, quantity) => {
    try {
      await updateProduct(productId, quantity);
      setProducts(prevProducts => 
        prevProducts.map(p => p.id === productId ? {...p, stock_quantity: quantity} : p)
      );
    } catch (error) {
      console.error('Error updating stock:', error);
      setError('Error al actualizar el inventario. Por favor, intenta de nuevo.');
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setProducts(prevProducts => 
      prevProducts.map(p => p.id === productId ? {...p, stock_quantity: newQuantity} : p)
    );
  };

  const clearTable = () => {
    setProducts([]);
    setSearchSku('');
  };

  const updateAllProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      for (let product of products) {
        await updateProduct(product.id, product.stock_quantity);
      }
      setError('Todos los productos han sido actualizados exitosamente.');
    } catch (error) {
      console.error('Error updating all products:', error);
      setError('Error al actualizar todos los productos. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Gestión de Inventario WooCommerce - MICBD</h1>
        <div>
          <Link 
            to="/statistics" 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Ver estadísticas
          </Link>
          <button 
            onClick={onLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Cerrar sesión
          </button>
        </div>
      </div>
      <div className="mb-4 flex justify-between items-center">
        <input 
          type="text" 
          placeholder="Buscar por SKU" 
          value={searchSku} 
          onChange={(e) => setSearchSku(e.target.value)}
          className="w-1/2 p-2 border rounded"
        />
        <div>
          <button 
            onClick={clearTable}
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Limpiar
          </button>
          <button 
            onClick={updateAllProducts}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Actualizar todo
          </button>
        </div>
      </div>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">SKU</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Imagen</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Nombre</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Precio</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Cant. Act.</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Cantidad</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Acciones</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {products.map((product) => (
                <tr key={product.id} className="border-b">
                  <td className="py-3 px-4">{product.sku}</td>
                  <td className="py-3 px-4">
                    <img src={product.images[0]?.src} alt={product.name} className="w-16 h-16 object-cover" />
                  </td>
                  <td className="py-3 px-4">{product.name}</td>
                  <td className="py-3 px-4">{product.price}</td>
                  <td className="py-3 px-4">{product.stock_quantity}</td>
                  <td className="py-3 px-4">
                    <input 
                      type="number" 
                      value={product.stock_quantity} 
                      onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                      className="w-20 p-1 border rounded"
                    />
                  </td>
                  <td className="py-3 px-4">
                    <button 
                      onClick={() => handleUpdateStock(product.id, product.stock_quantity)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Actualizar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductTable;