import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Tab } from '@headlessui/react';
import { fetchOrders, fetchProducts, fetchUsers } from '../api';
import { format, subDays, subMonths, subYears, parseISO } from 'date-fns';

const Statistics = () => {
  const [salesData, setSalesData] = useState([]);
  const [productSalesData, setProductSalesData] = useState([]);
  const [userSalesData, setUserSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('year');
  const [productSku, setProductSku] = useState('');
  const [userRole, setUserRole] = useState('');
  const [percentageToSubtract, setPercentageToSubtract] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [adjustedSales, setAdjustedSales] = useState(0);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Actualizar cada minuto
    return () => clearInterval(interval);
  }, [timeRange, productSku, userRole]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const orders = await fetchOrders();
      const products = await fetchProducts();
      const users = await fetchUsers();
      
      const filteredOrders = filterOrdersByDate(orders);
      const processedSalesData = processOrderData(filteredOrders);
      setSalesData(processedSalesData);
      
      if (productSku) {
        const productSales = processProductSalesData(filteredOrders, products, productSku);
        setProductSalesData(productSales);
      }
      
      if (userRole) {
        const userSales = processUserSalesData(filteredOrders, users, userRole);
        setUserSalesData(userSales);
      }
      
      const total = calculateTotalSales(processedSalesData);
      setTotalSales(total);
      setAdjustedSales(total * (1 - percentageToSubtract / 100));
    } catch (err) {
      setError('Error al cargar los datos. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const filterOrdersByDate = (orders) => {
    const now = new Date();
    let startDate;
    switch (timeRange) {
      case 'year':
        startDate = subYears(now, 1);
        break;
      case '3months':
        startDate = subMonths(now, 3);
        break;
      case 'month':
        startDate = subMonths(now, 1);
        break;
      case 'week':
        startDate = subDays(now, 7);
        break;
      default:
        startDate = subYears(now, 1);
    }
    return orders.filter(order => parseISO(order.date_created) >= startDate);
  };

  const processOrderData = (orders) => {
    const salesByDate = {};
    orders.forEach(order => {
      const date = format(parseISO(order.date_created), 'yyyy-MM-dd');
      salesByDate[date] = (salesByDate[date] || 0) + parseFloat(order.total);
    });
    return Object.entries(salesByDate).map(([date, total]) => ({ date, total }));
  };

  const processProductSalesData = (orders, products, sku) => {
    const product = products.find(p => p.sku === sku);
    if (!product) return [];
    
    const salesByDate = {};
    orders.forEach(order => {
      const productLine = order.line_items.find(item => item.product_id === product.id);
      if (productLine) {
        const date = format(parseISO(order.date_created), 'yyyy-MM-dd');
        salesByDate[date] = (salesByDate[date] || 0) + parseFloat(productLine.total);
      }
    });
    return Object.entries(salesByDate).map(([date, total]) => ({ date, total }));
  };

  const processUserSalesData = (orders, users, role) => {
    const roleUsers = users.filter(user => user.role === role);
    const salesByDate = {};
    orders.forEach(order => {
      if (roleUsers.some(user => user.id === order.customer_id)) {
        const date = format(parseISO(order.date_created), 'yyyy-MM-dd');
        salesByDate[date] = (salesByDate[date] || 0) + parseFloat(order.total);
      }
    });
    return Object.entries(salesByDate).map(([date, total]) => ({ date, total }));
  };

  const calculateTotalSales = (data) => {
    return data.reduce((sum, item) => sum + item.total, 0);
  };

  const handlePercentageChange = (e) => {
    const value = parseFloat(e.target.value);
    setPercentageToSubtract(value);
    setAdjustedSales(totalSales * (1 - value / 100));
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Estadísticas de Ventas</h1>
        <Link 
          to="/"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Volver
        </Link>
      </div>

      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl mb-4">
          <Tab className={({ selected }) =>
            `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
            ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
          }>
            Ventas Totales
          </Tab>
          <Tab className={({ selected }) =>
            `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
            ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
          }>
            Ventas por Producto
          </Tab>
          <Tab className={({ selected }) =>
            `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
            ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
          }>
            Ventas por Rol de Usuario
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="mb-4">
              <select 
                value={timeRange} 
                onChange={(e) => setTimeRange(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="year">Último año</option>
                <option value="3months">Últimos 3 meses</option>
                <option value="month">Último mes</option>
                <option value="week">Última semana</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="bg-blue-100 p-4 rounded-lg">
                <h3 className="text-lg font-semibold">Total de Ventas</h3>
                <p className="text-2xl font-bold">${totalSales.toFixed(2)}</p>
              </div>
              <div className="bg-green-100 p-4 rounded-lg">
                <h3 className="text-lg font-semibold">Ventas Ajustadas</h3>
                <p className="text-2xl font-bold">${adjustedSales.toFixed(2)}</p>
              </div>
            </div>
            <div className="mb-4">
              <input
                type="number"
                value={percentageToSubtract}
                onChange={handlePercentageChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                placeholder="Porcentaje a restar"
              />
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="total" stroke="#8884d8" name="Ventas Totales" />
                <Line type="monotone" dataKey="adjusted" stroke="#82ca9d" name="Ventas Ajustadas" />
              </LineChart>
            </ResponsiveContainer>
          </Tab.Panel>
          <Tab.Panel>
            <div className="mb-4">
              <input
                type="text"
                value={productSku}
                onChange={(e) => setProductSku(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                placeholder="Ingrese SKU del producto"
              />
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={productSalesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="total" stroke="#8884d8" name="Ventas del Producto" />
              </LineChart>
            </ResponsiveContainer>
          </Tab.Panel>
          <Tab.Panel>
            <div className="mb-4">
              <select 
                value={userRole} 
                onChange={(e) => setUserRole(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">Seleccione un rol</option>
                <option value="customer">Cliente</option>
                <option value="subscriber">Suscriptor</option>
                <option value="contributor">Contribuidor</option>
                <option value="author">Autor</option>
                <option value="editor">Editor</option>
                <option value="administrator">Administrador</option>
              </select>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={userSalesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="total" stroke="#8884d8" name="Ventas por Rol de Usuario" />
              </LineChart>
            </ResponsiveContainer>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Statistics;