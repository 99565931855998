import axios from 'axios';

const API_URL = 'https://micbd.mx/wp-json/wc/v3';

const api = axios.create({
  baseURL: API_URL,
  auth: {
    username: 'ck_c97ac92de168491673811840fb78f21a2c61b6f5',
    password: 'cs_fc97b629b8a22b517becd6082bcf00811a8c8dc0'
  }
});

export const fetchProducts = async (sku = '') => {
  try {
    const response = await api.get('/products', {
      params: {
        per_page: 100,
        sku: sku
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const updateProduct = async (productId, stockQuantity) => {
  try {
    const response = await api.put(`/products/${productId}`, {
      stock_quantity: stockQuantity
    });
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const fetchOrders = async () => {
  try {
    const response = await api.get('/orders', {
      params: {
        per_page: 100,
        status: 'completed'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await api.get('/customers', {
      params: {
        per_page: 100
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export default api;